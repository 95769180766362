function checkUploud(customAudioArr) {
    if($(`#backgroundmusic option[value='${customAudioArr}']`).length > 0){
      $(`#backgroundmusic option[value='${customAudioArr}']`).remove();
    }
  }

    $('#backgroundmusic').change(function (e){
      var id = $(this).val();
      var customAudioArr = "";

        if(id == "customOption"){
          e.preventDefault();
          $("#customMusicFile").trigger('click');

          $("#customMusicFile").change( function() {
          var fileName = $(this).val().split("\\").pop();
          if(fileName){
            checkUploud(customAudioArr);
            const newSelectBox = `
            <option value="3am">3am</option>
            <option value="Curtaincall">Curtaincall</option>
            <option value="Driftingoff">Driftingoff</option>
            <option value="Electronic">Electronic</option>
            <option value="Emptyfaces">Emptyfaces</option>
            <option value="Fairytale">Fairytale</option>
            <option value="Fuzzboy">Fuzzboy</option>
            <option value="Gogogone">Gogogone</option>
            <option id="customOption" value="customOption">--- Musik hochladen ---</option>
            <option value="${fileName}">${fileName}</option>`;

            $('#backgroundmusic').html(newSelectBox);
            // $('#backgroundmusic').append($(`<option value="${fileName}">${fileName}</option>`));
            $('#backgroundmusic').val(fileName);
            customAudioArr = fileName;
          }else{
            checkUploud(customAudioArr);
            $('#backgroundmusic').val("3am");
          }
          });
        }
});